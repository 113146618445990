import { ref, type Ref } from 'vue'

// The element can be undefined when the component is not mounted yet
export const useDraggable = (
  element: Ref<HTMLElement | undefined>,
  allowDrag: Ref<boolean>
) => {
  let x: number,
    y: number,
    startX: number,
    startY: number,
    isDragging: Ref<boolean> = ref(false)

  const onMouseMove = (e: MouseEvent) => {
    isDragging.value = true
    e.preventDefault()
    x = e.clientX - startX
    y = e.clientY - startY

    // ensure the element cannot be dragged outside of the viewport
    if (x < 0) x = 0
    if (y < 0) y = 0
    if (x + element.value!.offsetWidth > window.innerWidth)
      x = window.innerWidth - element.value!.offsetWidth
    if (y + element.value!.offsetHeight > window.innerHeight)
      y = window.innerHeight - element.value!.offsetHeight

    element.value!.style.transform = `translate(${x}px, ${y}px)`
  }

  const onMouseUp = () => {
    isDragging.value = false
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)
  }

  const initialize = (x: number, y: number): boolean => {
    if (element.value) {
      if (startX === undefined || startY === undefined) {
        startX = x
        startY = y
      } else {
        const transformXY = element.value.style.transform
          .slice('translate('.length, -1)
          .split(', ')
          .map((item) => +item.slice(0, -2))
        startX = x - transformXY[0]
        startY = y - transformXY[1]
      }
      return true
    }

    return false
  }

  return {
    onMouseDown: (e: MouseEvent) => {
      e.preventDefault()

      if (!allowDrag.value) return

      if (!initialize(e.clientX, e.clientY)) return

      document.addEventListener('mouseup', onMouseUp)
      document.addEventListener('mousemove', onMouseMove)
    },
    reset: () => {
      element.value!.style.transform = ''
    },
    isDragging,
    moveToCenter: () => {
      if (element.value == null) return

      const centerX = window.innerWidth / 2
      const centerY = window.innerHeight / 2

      const rect = element.value.getBoundingClientRect()
      if (startX === undefined && startY === undefined) {
        if (!initialize(rect.x, rect.y)) return
      }

      x = centerX - startX - rect.width / 2
      y = centerY - startY - rect.height / 2

      element.value.style.transform = `translate(${x}px, ${y}px)`
    }
  }
}
